<template>
  <div class="header-container">
    <div class="text-area">
      <label>{{pageTitle}}</label>
      <div class="msg">{{msg}}</div>
    </div>
    <div class="btn-area">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MngHeader',
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    msg: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.header-container{
  position: sticky;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 2001;
  box-shadow: 0 2px 6px #e9e9e9;
  .text-area{
    flex-basis: 300px;
    flex-grow: 1;
    label{
      display: block;
      font-size: 20px;
      margin-bottom: 6px;
    }
    .msg{
      font-size: 12px;
      color: #595b5d;
    }
  }
  .btn-area{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
    .el-button{
      height: 30px;
      min-width: 80px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
